/* ==========================================================================
 * Various
 * ========================================================================== */

div.settings div.file-field {
  /* force height on non-input field in the settings page */
  div,
  ul {
    margin-top: 40px;
  }

  /* but avoid to kill all file input */
  div {
    margin-top: inherit;
  }
}

.input-field label {
    pointer-events: auto !important;
}

.input-field label.active {
  font-size: 1rem;
}

nav .input-field input {
  margin: 0;
  padding-left: 0.5rem;
}

.tabs {
  display: flex;
}

.tab {
  flex: 1;
}
